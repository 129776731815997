@import '@/core/tamagoshi/scss';

@include size-below('sm') {
  .value {
    padding: 0 $spacingXs;
  }
}

@include sm() {
  .value {
    padding: 0 20px;
  }
}

.group {
  display: flex;
  margin-top: $spacingXs;
}

.flag {
  height: $spacingXl;
  width: $spacingXl;

  &:focus {
    outline: none;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba($ba500, 0.3);
  }

  &:not(:first-child) {
    margin-left: $spacingLg;
  }
}
