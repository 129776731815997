@import '@/core/tamagoshi/scss';

.container {
  @include size-below('md') {
    border-top: 1px solid $n100;
    padding-top: $spacingSm;
  }
}

.expander {
  @include size-above('md') {
    display: none;
  }
}

.block {
  &[data-expanded='false'] {
    display: none;
  }
}
