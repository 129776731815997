@import '@/core/tamagoshi/scss';

.container {
  margin-top: $spacingLg;
}

.links {
  display: flex;
  margin-top: $spacingSm;
}

.linkIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important; /* stylelint-disable-line declaration-no-important */
  height: auto !important; /* stylelint-disable-line declaration-no-important */
}

.link {
  position: relative;
  display: inline-block;
  color: $n0;

  overflow: hidden;
  width: 32px;
  height: 32px;

  text-decoration: underline;

  &:not(:first-child) {
    margin-left: 24px;
  }

  &:hover,
  &:focus {
    .linkIcon {
      top: auto;
      bottom: 0;
    }
  }
}
