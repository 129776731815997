@import '@/core/tamagoshi/scss/variables';

.contactTextWrapper {
  .desktop {
    @media (max-width: $smWidth) {
      display: none;
    }
  }
  .mobile {
    @media (min-width: $smWidth) {
      display: none;
    }
  }
}
