@import '@/core/tamagoshi/scss';

.container {
  padding-top: $spacingLg;

  @include size-below('md') {
    border-top: 1px solid $n100;
    margin-top: $spacingSm;
  }
}

.phone {
  margin: 0;
}

.phoneLink {
  margin-top: 0;
  margin-bottom: $spacingXs;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  @include text('body2', 'semiBold');
  color: $v600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.phoneIcon {
  margin-right: $spacingXXs;
}

.hours {
  margin: 0;
  @include text('small');
}
