@use '@/core/tamagoshiv2/tokens/colors';
@use '@/core/tamagoshiv2/tokens/spacings';
@import '@/core/tamagoshi/scss';

.links {
  display: flex;
  flex-wrap: wrap;
  gap: spacings.$s spacings.$m;
  justify-content: center;
  margin-top: spacings.$s;

  > * {
    display: inline;
    color: colors.$foundation-textIconInverted !important; /* stylelint-disable-line declaration-no-important */
  }
}

// NOTE Only used in .it.tsx
.companyInfo {
  margin-top: spacings.$m;
}
