@import '@/core/tamagoshi/scss';

.container {
  border: 1px solid $n100;
  border-radius: 50%;
  height: $spacingXXL1;
  width: $spacingXXL1;
  position: absolute;
  bottom: $spacingLg;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: $n100;

  @include size-below('md') {
    right: $spacingLg;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;

    &:before {
      border: 2px solid #21bcf4; // stylelint-disable-line color-no-hex
      content: '';
      position: absolute;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      display: block;
    }
  }
}

.arrow {
  height: 30px;
  width: 30px;
  transform: rotate(-90deg);
}
