@import '@/core/tamagoshi/scss';

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  text-align: inherit;
  padding: 0;
  color: $n100;
  font-family: mm-main, 'Open Sans', arial, sans-serif;
}

.text {
  display: inline-block;
  margin-bottom: $spacingXs;
}

.title {
  @include text('body1', 'bold');
  display: inline-block;
  width: 100%;
  color: $n100;
}

.subTitle {
  @include text('body2', 'semiBold');
  display: inline-block;
  width: 100%;
  color: $n100;
}

.expander {
  @include size-above('md') {
    display: none;
  }
}

.block {
  @include size-below('md') {
    &[data-is-expandable='true'] {
      &[data-expanded='false'] {
        display: none;
      }
    }
  }
}
