@import '@/core/tamagoshi/scss';

// Payment
.titleBlock {
  display: flex;
  align-items: center;
}

.lock {
  font-size: 21px;
  margin-right: 8px;
}

.title {
  @include text('body1', 'bold');
}

// PaymentMeans
.container {
  margin-top: $spacingXs;
  display: inline-flex;
  flex-wrap: wrap;
  gap: $spacingXs;

  > svg {
    height: 32px;
    width: 48px;
  }
}
