@import '../scss';

.linkListItem {
  @include text('small');
  font-family: $fontFamily;
  list-style: none;

  a {
    color: inherit;
    text-decoration: none;
    outline: none;
    cursor: inherit;
    width: 100%;
    font-weight: 400;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
    }

    &:visited {
      color: inherit;
    }

    &:focus {
      text-decoration: underline;
    }
  }
}

.linkListItemDark {
  color: $n0;
}

.linkListItemLight {
  color: $n800;
}

.linkListItemSecondary {
  color: $secondaryColor;
}

.linkListItemPrimary {
  color: $primaryColor;
}
