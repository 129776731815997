/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-disable color-hex-length */
/* stylelint-disable color-no-hex */

/// This palette contains the base colors defined by the brand.
/// They should never be used directly!

// Balata
$Ba100: #d4fbea;
$Ba200: #aaf8dc;
$Ba300: #7ceacd;
$Ba400: #58d5bf;
$Ba500: #29b9ad;
$Ba600: #1d9e9f;
$Ba700: #147a85;
$Ba800: #0d596b;
$Ba900: #074258;

// Blue
$B100: #e9efff;
$B200: #c4dbff;
$B300: #91b2ec;
$B400: #335ab1;
$B500: #1e3c87;
$B600: #152e74;
$B700: #0f2161;
$B800: #09174e;
$B900: #050f40;

// Clay
$C100: #eaf0e7;
$C200: #dde6d8;
$C300: #cfdcc8;
$C400: #c4d5bc;
$C500: #bacdb0;
$C600: #b3c8a9;
$C700: #abc1a0;
$C800: #a3ba97;
$C900: #94ae87;

// Copper
$Co100: #fee8cc;
$Co200: #fdd8a8;
$Co500: #f46e00;

// Cream
$Cr100: #fcf7eb;
$Cr200: #fbf2dd;
$Cr300: #f9eccf;
$Cr400: #f7e8c5;
$Cr500: #f6e4bb;
$Cr600: #f5e1b5;
$Cr700: #f3ddac;
$Cr800: #f2d9a4;
$Cr900: #efd196;

// Error
$Er100: #ffeded;
$Er150: #ffa7ab;
$Er200: #ff6d74;
$Er500: #ff000c;
$Er700: #c70a13;

// Eucalyptus
$E100: #bcd7ce;
$E200: #90bcad;
$E300: #64a18c;
$E400: #428c74;
$E500: #21785b;
$E600: #1d7053;
$E700: #186549;
$E800: #145b40;
$E900: #0b482f;

// Gold
$Go100: #fff2ce;
$Go200: #ffdf9b;
$Go500: #ffb950;

// Info
$I100: #f0f4ff;
$I500: #295fe4;

// Loyalty
$Lo200: #ffebb6;
$Lo300: #ffe193;

// Prusse
$P100: #b6cdd3;
$P200: #86acb5;
$P300: #568b97;
$P400: #317281;
$P500: #0d596b;
$P600: #0b5163;
$P700: #094858;
$P800: #073e4e;
$P900: #032e3c;

// Red
$R100: #ffd2cc;
$R200: #f9a2a2;
$R300: #f67c7c;
$R400: #f46060;
$R500: #f24444;
$R600: #f03e3e;
$R700: #ee3535;
$R800: #ec2d2d;
$R900: #e81f1f;

// Silver
$Si100: #ecedf0;
$Si200: #d8dadf;
$Si500: #8b91a1;

// Squidink
$Sq000: #ffffff;
$Sq100: #fafafa;
$Sq200: #f5f6f7;
$Sq300: #ecedf0;
$Sq400: #d8dadf;
$Sq500: #b1b5c0;
$Sq600: #8b91a1;
$Sq700: #505971;
$Sq800: #0c193a;
$Sq900: #070f22;

// Success
$Su100: #ecfdec;
$Su200: #abfbab;
$Su500: #00a040;

// Va a la playa
$V100: #fff8e3;
$V200: #ffebb6;
$V300: #ffdf9b;
$V400: #ffc665;
$V500: #ffb950;
$V600: #f0a22d;
$V700: #dd891d;
$V800: #a3670c;
$V900: #7a4a0f;

// Violet
$Vi100: #f5f0ff;
$Vi200: #dcc8ff;
$Vi800: #391a8c;

// Warning
$W100: #fff2de;
$W200: #ffe193;
$W500: #ff7a00;
