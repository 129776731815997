@import '@/core/tamagoshi/scss';

.container {
  padding-top: $spacingLg;

  @include size-below('md') {
    border-top: 1px solid $n100;
    margin-top: $spacingSm;
  }
}
