@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '@/core/tamagoshiv2/tokens/spacings';
@import '@/core/tamagoshi/scss/mixins';
@import '@/core/tamagoshiv2/tokens/colors/_palette';

$footerColor: $Sq100;
$footerFontFamily: $fontFamily;
$footerColumnWidth: 332px;

.footer {
  background-color: colors.$foundation-backgroundQuinary;
  font-family: $fontFamily;
  color: $footerColor;
}

.grid {
  margin-left: auto;
  margin-right: auto;
  padding: spacings.$l spacings.$m;
  width: 100%;
  position: relative;

  display: grid;

  grid-template-areas:
    'contact about other'
    'awardsBanner awardsBanner awardsBanner';

  @include size-below('md') {
    display: inline-block;
  }

  @include size-above('md') {
    max-width: 1060px;
    grid-template-columns: $footerColumnWidth $footerColumnWidth $footerColumnWidth;
  }
}

.contact {
  grid-area: contact;
}

.about {
  grid-area: about;

  @include size-below('md') {
    margin-top: $spacingSm;
  }
}

.other {
  grid-area: other;
}

.awardsBanner {
  grid-area: awardsBanner;
}

.column {
  width: 100%;

  @include size-above('md') {
    width: $footerColumnWidth;
    max-width: $footerColumnWidth;
  }
}

.legal {
  background-color: colors.$foundation-backgroundQuinary;
  padding: spacings.$m;
  text-align: center;
}

.supportSmall {
  @include size-above('md') {
    display: none;
  }
}

.supportLarge {
  @include size-below('md') {
    display: none;
  }
}
